import React from 'react'
import { graphql } from 'gatsby'
import ArtPageTpl from 'templates/art/page'
import { prepareListsForGallery } from 'utils/gallery-preparation'

export default function ArtPage({
	data: {
		prismicArtStand: { data },
	},
}) {
	data.list = data.list.filter(({ is_visible }) => is_visible)

	return <ArtPageTpl {...data} lists={prepareListsForGallery(data.list)} />
}

export const query = graphql`
	query ArtPageQuery {
		prismicArtStand {
			_previewable
			data {
				heading
				bold_paragraph {
					richText
				}
				list {
					author
					title
					image: art_image {
						alt
						url
						dimensions {
							height
							width
						}
					}
					is_visible
				}
			}
		}
	}
`
