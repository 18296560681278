function prepareListsForGallery(list) {
	list.forEach(({ image }) => {
		image.orientation = getOrientation(image.dimensions)
	})

	const full = uniteVerticalImages(list)
	const components = organizeByOrientationComponents(full)
	const imagesOnly = full.map(({ image }) => image)

	return { full, components, imagesOnly }
}

const getOrientation = ({ height, width }) =>
	height >= width ? 'vertical' : 'horizontal'

function uniteVerticalImages(list) {
	const groupedList = []
	const usedIndices = []
	let currentIndex = 0

	while (usedIndices.length < list.length) {
		if (usedIndices.indexOf(currentIndex) > -1) {
			currentIndex++
			continue
		}

		if (list[currentIndex].image.orientation === 'horizontal') {
			groupedList.push(list[currentIndex])
		} else {
			groupedList.push(list[currentIndex])

			let rightItemIndex = currentIndex + 1
			while (
				rightItemIndex < list.length &&
				(usedIndices.indexOf(rightItemIndex) > -1 ||
					list[rightItemIndex].image.orientation !== 'vertical')
			) {
				rightItemIndex++
			}

			if (rightItemIndex < list.length) {
				groupedList.push(list[rightItemIndex])
				usedIndices.push(rightItemIndex)
			}
		}
		usedIndices.push(currentIndex)
		currentIndex++
	}

	return groupedList
}

function organizeByOrientationComponents(list) {
	const components = []
	let i = 0

	while (i < list.length) {
		if (list[i].image.orientation === 'horizontal') {
			components.push({
				component: 'horizontal',
				items: [
					{
						image: list[i].image,
						indexInList: i,
					},
				],
			})
			i++
		} else {
			const leftItem = {
				image: list[i].image,
				indexInList: i,
			}
			i++
			let rightItem = null
			if (i < list.length && list[i].image.orientation === 'vertical') {
				rightItem = {
					image: list[i].image,
					indexInList: i,
				}
				i++
			}
			components.push({
				component: 'vertical',
				items: [leftItem, rightItem],
			})
		}
	}

	return components
}

module.exports = { prepareListsForGallery }
