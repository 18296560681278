import React from 'react'
import { PrismicRichText } from '@prismicio/react'
import { Root } from 'templates/layout'
import { DesignRow, Heading1, Heading5, IntenseText } from 'components/design'
import GalleryWithSlideshow from 'templates/gallery-with-slideshow'

const ArtPageTpl = ({
	bold_paragraph,
	heading,
	lists: { components, full, imagesOnly },
}) => {
	const buildDescriptorByIndex = (index) => (
		<div className="mt-4">
			<Heading5 className="inline">{full[index].title}. </Heading5>
			{full[index].author ? (
				<span className="text-pale">Author: {full[index].author}</span>
			) : null}
		</div>
	)

	return (
		<Root>
			<DesignRow
				className="mt-72"
				leftColumn={<Heading1>{heading}</Heading1>}
				rightColumnWithGutter={
					<IntenseText className="lg:mt-5">
						<PrismicRichText field={bold_paragraph.richText} />
					</IntenseText>
				}
			/>
			<GalleryWithSlideshow
				componentList={components}
				imageList={imagesOnly}
				buildDescriptorByIndex={buildDescriptorByIndex}
			/>
		</Root>
	)
}

export default ArtPageTpl
